@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  background-color: #F6F6F6;
  overflow-x: hidden;
  color: #222220!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="file"], textarea, select {
  width: 100%;
  padding: 20px!important;
  font-size: 13px;
  font-family: inherit;
  color: #000;
  /*background-color: #f6f6f6;*/
  border: 1px solid rgb(38 38 38);
}

.ascale-react-select {
  width: 100%;
  font-family: inherit;
  border: 1px solid rgb(38 38 38)!important;
  border-radius: 0.5rem!important;
  background-color: transparent!important;
  font-size: 0.875rem!important;
  color: rgb(26 32 44 / var(--tw-text-opacity))!important;
}

.ascale-react-select-inner {
  color: rgb(26 32 44 / var(--tw-text-opacity))!important;
  font-size: 0.875rem!important;
  padding-left: 20px!important;
  margin: 0!important;
}

.ascale-react-select-input {
  color: rgb(26 32 44 / var(--tw-text-opacity))!important;
  font-size: 0.875rem!important;
}

.ascale-react-select-input input {
  padding: 20px 0px 20px 0px!important;
  margin-left: 20px!important;
}

.ascale-react-select-option {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity))!important;
  z-index: 9999!important;
}

.ascale-react-select-menu-list {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity))!important;
  z-index: 9999!important;
}

.btn-tau {
  color: #fff;
  border: 2px solid #000;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: .7rem;
  font-weight: 500;
  height: 48px;
  background-color: #000;
}

h1 {
  font-size: 2.95em;
  padding: 1.25rem 0 1rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 3px;
}

table.table {
  border: 1px solid #d0d1cc;
}

thead {
  color: #fff;
  border: 2px solid #000;
  background-color: #000;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 1;
  font-size: .8rem;
  font-weight: 500;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.shadow-drop-bottom {
  -webkit-box-shadow:0 20px 20px -20px rgba(34,34,32,.25);
  box-shadow:0 20px 20px -20px rgba(34,34,32,.25)
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: .7s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: .7s;
  animation-fill-mode: both;
}



@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
  animation-duration: .7s;
  animation-fill-mode: both;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
  animation-duration: .7s;
  animation-fill-mode: both;
}

.border-animation {
  --border-width: 2px;
  --animation-speed: 0.5s;
  --color: #222220;
  color: var(--color);
  position: relative;
  display: inline-block;
  transform: scale(1, 0.8);
  border: var(--border-width) solid var(--color);
}

.border-animation:hover {
  animation: borderOut var(--animation-speed) forwards ease-in-out;
}

@keyframes borderOut {
  0% {
    border-width: calc(var(--border-width) * 1);
  }
  33% {
    border-width: calc(var(--border-width) * 0.6);
  }
  66% {
    border-width: calc(var(--border-width) * 0.3);
  }
  100% {
    border-width: calc(var(--border-width) * 0);
  }
}